import { popoverAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";
import { cssVar, mode } from "@chakra-ui/theme-tools";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const $popperBg = cssVar("popper-bg");

const $arrowBg = cssVar("popper-arrow-bg");
const $arrowShadowColor = cssVar("popper-arrow-shadow-color");

const baseStylePopper = defineStyle({
  zIndex: "popover",
});

const baseStyleContent = defineStyle((props) => {
  const bg = mode("white", "neutral.2")(props);
  const shadowColor = mode("white", "#000")(props);

  return {
    bg,
    shadow: "md",
    borderRadius: "12px",
    border: "1px solid",
    borderColor: mode(
      "neutral.stroke.bold_light_theme",
      "neutral.stroke.bold"
    )(props),
    width: "380px",
    boxShadow: "2xl",
    zIndex: "inherit",
    _focusVisible: {
      outline: 0,
      boxShadow: "2xl",
    },
  };
});

const baseStyleHeader = defineStyle({
  px: 3,
  py: 2,
  borderBottomWidth: "1px",
});

const baseStyleBody = defineStyle({
  p: "16px",
});

const baseStyleFooter = defineStyle({
  px: 3,
  py: 2,
  borderTopWidth: "1px",
});

const baseStyleCloseButton = defineStyle({
  position: "absolute",
  borderRadius: "md",
  top: 1,
  insetEnd: 2,
  padding: 2,
});

const baseStyle = definePartsStyle((props) => ({
  popper: baseStylePopper,
  content: baseStyleContent(props),
  header: baseStyleHeader,
  body: baseStyleBody,
  footer: baseStyleFooter,
  arrow: {},
  closeButton: baseStyleCloseButton,
}));

const Popover = defineMultiStyleConfig({
  baseStyle,
});

export default Popover;
