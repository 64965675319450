import { cssVar, Tooltip as TooltipComponent } from "@chakra-ui/react";
import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";
import { mode } from "@chakra-ui/theme-tools";

const $arrowBg = cssVar("popper-arrow-bg");

const baseStyle = defineStyle((props) => {
  const bg = mode("white", "neutral.stroke.light")(props);
  const fg = mode("neutral.2", "neutral.element.primary")(props);

  return {
    bg,
    color: fg,
    fontWeight: 500,
    padding: "5px 10px",
    borderRadius: "12px",
    maxWidth: props.maxWidth || props.maxW || "300px",
    [$arrowBg.variable]: bg,
    zIndex: 1500,
  };
});

const Tooltip = defineStyleConfig({
  baseStyle,
});

TooltipComponent.defaultProps = {
  ...TooltipComponent.defaultProps,
  hasArrow: true,
};

export default Tooltip;
